import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const EbonieRoberge = () => (
  <Layout>
    <SEO
      title="Travel The World and Natural Hair with Ebonie Roberge"
      description="We talk natural hair with the beautiful Ebonie Roberge. We learned that the journey to a perfect natural hair routine can be a long one"
    />
    <h1>Travelling The World and Talking Natural Hair with Ebonie Roberge</h1>

    <p>This week we spoke with the incredible entrepreneur, host and director from Montreal, Ebonie Finley Roberge. In keeping with her web series Nomad Generation and aim to travel the world; when we spoke, Ebonie was travelling from Montreal to Vancouver.... by car! If you love travel blogs, definitely check out <a
        href="https://www.facebook.com/GenerationNomadeEb/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Nomad Generation
      </a> and Ebonie's instagram <a
        href="https://www.instagram.com/eb_finley/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @eb_finley
      </a></p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/nTjD4M8.jpg"
        alt="Ebonie Finley Roberge smile natural afro hair care"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Ebonie Roberge</p>
    </div>

    <h2>Hello Ebonie, tell us a bit about yourself?</h2>
    <p>I'm from Montreal, half Bahamian and half Canadian and shared my childhood between both countries. From a young age, I discovered a passion for traveling and modeling. I was an international model from 16 to 24 years, leading a career from Montreal to South Africa and participating in Canada's Next Top Model ( season 3 ).</p>
    <p>At 24, I started a bachelor's in Business marketing and graduated in 2016. This led me to create a web series called <a
        href="https://www.facebook.com/GenerationNomadeEb/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Nomad Generation
      </a> that generated over 1,3 million views on social media and to travel the world as a host and director. I also participated at The Amazing Race Canada ( season 5 ).</p>

      <h2>What does your hair mean to you?</h2>
      <p>It means accepting your natural beauty! In my modeling years, I had to straighten my hair and felt like my natural hair wasn't beautiful enough for the industry standards. Luckily, things have changed today and I have learned to accept my hair, and my beauty, the way they naturally are.</p>

      <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/l6t6ZaJ.jpg"
        alt="Ebonie Finley Roberge profile natural afro hair care"
      />
    </div>

    <h2>How would you describe your hair?</h2>
    <p>ahaha diva tight curls that are a bit difficult to manage ... or I haven't found the perfect recipe just yet</p>

    <h2>Talk us through your hair routine</h2>
    <p>It's been a lot of trying and failing. I never really stuck to anything as I am not really comfortable with my hairs need and would follow advice from anyone. One girl said she uses coconut oil, I would do that for a couple of months until I read only that it breaks the curl pattern and would stop. I would do that with every new product I tried. I am now tired of always changing and not seeing the results that I want so for now, I am sticking to <a
        href="https://www.renefurtererusa.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
       Rene Furterer
      </a> products, the Moringa collection for curly dry hair. I wash my hair once a week but I wet it every day and apply some leave-in conditioner. I still use coconut oil but just on my ends and I sleep with a silk pillow to protect my hair. I'm crossing my fingers that this is good recipe for my hair to grow pass my shoulders.</p>

      <h2>How did you view your hair growing up?</h2>
      <p>I would get bullied a lot because I grew up in a very white neighborhood. I would get called '' hay box '' and kids would stick pencils, gum and all sorts of stuff in my hair. My mom took care of my hair and would braid it because it was less maintenance until I was around 12 and started using strong straighteners and extensions in my hair. I finally stopped all of this nonsense around the age of 26.</p>
      
      <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/vprLMJg.jpg"
        alt="Ebonie Finley Roberge young natural afro hair care"
      />
    </div>

    <h2>Where did you learn to take care of your hair?</h2>
    <p>I'm honestly still learning and trying to find what my hair needs. I'm just experimenting with my hair until I find the perfect recipe.</p>

    <h2>Any resources you use now?</h2>
    <p>YouTube and Instagram are my 2 main sources of information.</p>

    <h2>How do you feel about doing your hair yourself?</h2>
    <p>Sometimes I do want to quit and just go back to straightening my hair. It gets quite frustrating when you feel like you are trying your best but I don't see any growth or changes with my hair. For me, it's still a challenge.</p>

    <h2>Anything you wish you knew sooner?</h2>
    <p>GROW GROW GROW !!!</p>

    <h2>What advice would you give for taking care of natural hair?</h2>
    <p>Don't listen to what everyone is saying, a lot of things contradicting themselves on the web ! Just do what feels right and good for you and give you the best results.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/iDAzmQh.jpg"
        alt="Ebonie Finley Roberge side pose Natural Afro Hair care"
      />
    </div>

    <h2>What is your favourite hairstyle right now?</h2>
    <p>I love doing a high bun or 2 small braids.</p>

    <h2>Do you have any hair plans for the future?</h2>
    <p>I'm going to stick with the <a
        href="https://www.renefurterer.com/en/en"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Rene Furterer
      </a>  products for at least 3 months and give them a fair chance to see if I notice a difference.</p>

      <h2>Can you tell us about any projects you are working on?</h2>
      <p>I'm working on a second season on Nomad Generation so stay tuned</p>

      <h2>How can we follow/ contact you?</h2>
    <p>Instagram - <a
        href="https://www.instagram.com/eb_finley/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @eb_finley
      </a> </p>
    <p>Facebook - <a
        href="https://www.facebook.com/GenerationNomadeEb/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      @GenerationNomadeEb
      </a> </p>



    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}


      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/talking-natural-hair-with-elise/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            First Year Doing it Herself - Elise Talks Natural Hair
            </Link>{" "}
            - We had a chat with the amazing Elise Campbell. She has been looking after her own 4c hair for a year now after taking over from her mother...
          </p>
          <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>          
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default EbonieRoberge
